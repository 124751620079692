import { FormSteps } from '../types/global';
import { FormPages } from '../types/steps';

export const indexPage: FormPages = 'species';
export const indexStep: FormSteps = 'species';

export const topLevelSteps = [
  'species',
  'petDetails',
  'activityLevel',
  'policyStartDate',
  'paymentMethod',
  'confirmation',
  'review',
  'continueCover',
  'retrieveQuote',
];

export const topLevelStepsIE = [
  'species',
  'customerDetails',
  'activityLevel',
  'policyStartDate',
  'paymentMethod',
  'confirmation',
  'review',
  'continueCover',
  'retrieveQuote',
];

export const stepToPageMap: Record<FormSteps, FormPages> = {
  species: 'species',
  petDetails: 'details',
  petHistory: 'details',
  customerDetails: 'details',
  activityLevel: 'cover',
  policyStartDate: 'cover',
  coverLevel: 'cover',
  fixedExcess: 'cover',
  features: 'cover',
  declaration: 'cover',
  review: 'review',
  paymentMethod: 'payment',
  paymentTransaction: 'payment',
  confirmation: 'confirmation',
  continueCover: 'continue-cover',
  retrieveQuote: 'retrieve',
};

export const stepToPageMapCustomerFirst: Record<FormSteps, FormPages> = {
  customerDetails: 'details',
  species: 'species',
  petDetails: 'details',
  petHistory: 'details',
  activityLevel: 'cover',
  policyStartDate: 'cover',
  coverLevel: 'cover',
  fixedExcess: 'cover',
  features: 'cover',
  declaration: 'cover',
  review: 'review',
  paymentMethod: 'payment',
  paymentTransaction: 'payment',
  confirmation: 'confirmation',
  continueCover: 'continue-cover',
  retrieveQuote: 'retrieve',
};
